<template>
  <div>
    <!-- 用户协议配置 -->
    <el-form :model="docData" :rules="rules" ref="docData" label-width="180px" class="demo-ruleForm"
      style="margin-top: 10px; margin-left: 3%">
      <el-form-item :label="$t('System.CustomizeConfigPage.PopupTitle')" prop="title">
        <el-input size="small" v-model="docData.title" style="width: 400px; margin-right: 10px;height:100px'">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('System.CustomizeConfigPage.PopupContent')" prop="content">
        <el-input size="small" v-model="docData.content" style="width: 800px; margin-right: 10px" type="textarea"
          :rows="5"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.CustomizeConfigPage.DetailsLinkLabel')" prop="detail.title">
        <el-input size="small" v-model="docData.detail.title" style="width: 400px; margin-right: 10px;height:300px'">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('System.CustomizeConfigPage.LicenseAgreement')" prop="detail.content">
        <el-input size="small" v-model="docData.detail.content" style="width: 800px; margin-right: 10px" type="textarea"
          :rows="15"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width:80px;" size="small" type="primary" @click="saveForm"> {{ $t("public.Save") }}
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 保存成功提示组件 -->
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo"
      :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from '@/components/promptMessage/index';

export default {
  components: {
    dialogInfo
  },
  data() {
    return {
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: '', // 提示框的提示语
      docData: {
        title: "",
        content: "",
        detail: {
          title: "",
          content: "",
        }
      },
      rules: {
        title: [{ required: true, message: this.$t('System.CustomizeConfigPage.PopupTitleVoid'), trigger: "blur" }],
        content: [{ required: true, message: this.$t('System.CustomizeConfigPage.PopupContentVoid'), trigger: "blur" }],
        detail: {
          title: [{ required: true, message: this.$t('System.CustomizeConfigPage.DetailsLinkLabelVoid'), trigger: "blur" }],
          content: [{ required: true, message: this.$t('System.CustomizeConfigPage.LicenseAgreementVoid'), trigger: "blur" }],
        }
      },
    };
  },
  created() { },
  mounted() {
    this.getDataList();
  },
  methods: {
    // 获取
    async getDataList() {
      var parma = {};
      const res = await this.$axios.post("/httpServe/agreement/selectOne", parma, true);
      console.log(res);
      this.docData = res.data;
    },
    // 保存
    saveForm() {
      this.$refs.docData.validate(async (valid) => {
        if (valid) {
          const res = await this.$axios.post("/httpServe/agreement/update", this.docData, true)
          if (res.data == 1) {
            this.$message({
              message: this.$t('public.SuccessfullySaved'),
              type: 'success',
              offset: 100
            });
          } else {
            this.$message({
              message: this.$t('System.CustomizeConfigPage.fileDoesNotExist'),
              type: "error",
              offset: 100,
            });
          }
        } else {
          console.log("error");
        }
      });
    },
    // // 关闭提示框
    handleClose() {
      this.visibaelFlag = false //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false //提示消息  关闭
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-form-item {
  margin-bottom: 17px;
}

::v-deep .el-textarea__inner {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
::v-deep .el-input__inner {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
</style>