<template>
  <div>
    <!-- 短信模板管理 -->
    <el-row :gutter="16" class="mt16" style=" margin: 0 10px;" v-if="flag == 1">
      <el-table size="mini" stripe ref="tableData" :data="tableData" class="sort_table" tooltip-effect="dark" style="width: 100%;" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }" :default-sort="{ prop: 'updateTime', order: 'descending' }" @sort-change="sort">
        <el-table-column prop="name"  :label="$t('System.CustomizeConfigPage.TemplateName')" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <!-- -短信模版管理，列表增加模版ID字段 -->
        <el-table-column prop="templateCode" :label="$t('System.CustomizeConfigPage.TemplateId')"  width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="content" :label="$t('System.CustomizeConfigPage.TemplateContent')"  width="420" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="updateTime" :label="$t('System.CustomizeConfigPage.ModificationTime')" :show-overflow-tooltip="true" width="180">
          <template v-slot="{ row }">
            {{ row.updateTime | dateformat }}
          </template>
        </el-table-column>
        <el-table-column  :label="$t('public.Controls')">
          <template v-slot="{ row }">
            <span class="action_icon">
              <img @click="edit(row)" :title="$t('public.Editor')" src="@/assets/icon_write.png" alt="" style=" width: 15px; height: 15px; cursor: pointer;" />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-right:10px;">
        <pagination :page="currentPage" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
      </div>
    </el-row>
    <!-- 编辑 -->
    <editNote v-else :dictData="editData" @editFlag="editFlag" />
    <!-- 保存成功提示组件 -->
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import pagination from '@/components/pagination/page.vue' //分页
import dialogInfo from '@/components/promptMessage/index'
import editNote from './components/editNote.vue'
export default {
  components: {
    pagination,
    dialogInfo,
    editNote
  },
  data() {
    return {
      visibaelFlag: false, //提示消息默认--关闭
      editVisible: false,
      titleInfo: '', // 提示框的提示语
      flag: 1,
      pageSize: 10, //每页条数,  默认10条
      total: 0, //总条数
      currentPage: 1,
      tableData: [],
      editData: {},
    }
  },
  created() {},
  mounted() {
    this.getDataList()
  },
  methods: {
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:3px 0px'
    },
    // 获取列表
    async getDataList() {
      var parma = { currentPage: this.currentPage, pageSize: this.pageSize }
      const res = await this.$axios.post(
        '/httpServe/template/sms/list',
        parma,
        true
      )
      console.log(res)
      this.tableData = res.data.content
      this.total = res.data.total
    },
    // 排序
    sort() {},
    // 编辑
    edit(val) {
      console.log(val, 'edit')
      this.editData = val
      this.flag = 2
    },
    // 编辑后提示
    editFlag(val) {
      if (val.data == 1) {
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
        this.getDataList()
        this.flag = 1
      } else {
        this.$message({
          message: this.$t('System.CustomizeConfigPage.SaveFailed'),
          type: 'error',
          offset: 100
        })
      }
    },
    // 关闭提示框
    handleClose() {
      this.visibaelFlag = false //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false //提示消息  关闭
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page
      this.pageSize = limit
      // 调用查询方法
      this.getDataList()
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-form-item {
  margin-bottom: 17px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
