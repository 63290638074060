<template>
    <div>
        <!-- 编辑短信模板 -->
        <el-form :model="dictData" ref="dictData" label-width="180px" class="demo-ruleForm"
            style="margin-top: 10px; margin-left: 3%">
            <el-form-item :label="$t('System.CustomizeConfigPage.TemplateName')+'：'" prop="name">
                <el-input size="small" v-model="dictData.name" style="width: 250px; margin-right: 10px;'" disabled>
                </el-input>
            </el-form-item>
            <!-- 编辑，增加模版ID配置 -->
            <el-form-item :label="$t('System.CustomizeConfigPage.TemplateId')+'：'" prop="templateCode">
                <el-input size="small" v-model="dictData.templateCode" :placeholder="$t('System.CustomizeConfigPage.TemplateIdViod')" style="width: 250px; margin-right: 10px;'" >
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('System.CustomizeConfigPage.TemplateContent')+'：'" prop="content">
                <el-input size="small" v-model="dictData.content" style="width: 250px; margin-right: 10px;'"
                    type="textarea" :rows="4">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="saveForm">{{ $t("public.Save") }}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>

export default {
    props: {
        dictData: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        saveForm() {
            this.$refs.dictData.validate(async (valid) => {
                if (valid) {
                    if (this.dictData.content == "") {
                        this.$message({
                            message: this.$t('System.CustomizeConfigPage.TemplateContentViod'),
                            type: 'error',
                            offset: 100
                        })
                        return false;
                    }
                    const res = await this.$axios.post("/httpServe/template/sms/update", this.dictData, true)
                    this.$emit("editFlag", res)

                } else {
                    console.log("error");
                }
            });
        },
    }
}
</script>

<style lang='scss' scoped>
::v-deep .el-textarea__inner {
    font-family: Microsoft YaHei;
    font-size: 12px;
}

::v-deep .el-input__inner {
    font-family: Microsoft YaHei;
    font-size: 12px;
}
</style>
