<template>
  <div>
    <!-- 页面自定义 -->
    <el-form :model="docData" :rules="rules" ref="docData" label-width="180px" class="demo-ruleForm"
      style="margin-top: 10px; margin-left: 3%">
      <el-form-item :label="$t('System.CustomizeConfigPage.EnterpriseLogo')">
        <div class="w400 gray" style="
            border: dashed 1px;
            height: 90px;
            text-align: center;
            float: left;
          ">
          <img :src="docData.fileName" style="max-height: 90px" />
        </div>
        <el-upload size="small" action="'/httpServe/sysFile/fileUp'" :auto-upload="false" :show-file-list="false"
          class="el-button uploadBox" style="padding: 0" :on-success="handleSuccess" :on-error="handleError"
          :file-list="fileList" :on-change="handlePictureCardPreview" multiple accept="." list-type="picture">
          <el-button size="small" style="
              position: relative;
              background: #e5981e;
              border: none;
            ">
            <span style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              ">
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{ $t("System.CustomizeConfigPage.SelectLocalImage") }}</span>
          </el-button>
        </el-upload>
        <span style="display: block" class="msg_grey">{{ $t("System.CustomizeConfigPage.imageSize") }}</span>
      </el-form-item>
      <el-form-item :label="$t('System.CustomizeConfigPage.PlatformName')" prop="name">
        <el-input size="small" v-model="docData.name" maxlength="20" style="width: 400px; margin-right: 10px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width:80px;" size="small" type="primary" @click="saveForm">{{ $t("public.Save") }}
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 保存成功提示组件 -->
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo"
      :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from '@/components/promptMessage/index';

export default {
  components: {
    dialogInfo
  },
  data() {
    return {
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: '', // 提示框的提示语
      path: '/msp/protected/config/logo/logo.jpg',
      imgUrl: vueConfig.jqUrl.slice(0,vueConfig.jqUrl.length-1),
      docData: {
        name: "",
        fileName: '',
      },
      rules: {
        name: [{ required: true, message: this.$t('System.CustomizeConfigPage.PlatformNameVoid'), trigger: "blur" }],
      },
      fileList: [],
    };
  },
  created() { },
  mounted() {
    this.getDataList();
  },
  methods: {
    // 获取
    async getDataList() {
      var parma = {};
      const res = await this.$axios.post("/httpServe/customConfig/selectOne", parma, true);
      console.log(res);
      this.docData.fileName = this.imgUrl + this.path;
      this.docData.name = res.data.name;
      console.log(this.docData, "this.docData111");
    },
    // 保存
    saveForm() {
      this.$refs.docData.validate(async (valid) => {
        if (valid) {
          const parma = {
            name: this.docData.name,
          }
          const res = await this.$axios.post("/httpServe/customConfig/update", parma, true)
          if (res.data == 1) {
            this.$message({
              message: this.$t('public.SuccessfullySaved'),
              type: 'success',
              offset: 100
            });
          } else {
            this.$message({
              message: this.$t('System.CustomizeConfigPage.fileDoesNotExist'),
              type: "error",
              offset: 100,
            });
          }
        } else {
          console.log("error");
        }
      });
    },
    // 图片回显
    async handlePictureCardPreview(file) {
      if (file.size / (1024 * 1024) > 0.3) {
        //   限制文件大小
        this.$message.warning(this.$t('System.CustomizeConfigPage.LimitFileSize'));
        return false;
      }
      let fileName = file.name;
      let suffixArray = ["jpg"]; //限制的文件类型，根据情况自己定义
      let suffix = file.name?.substring(fileName.lastIndexOf(".") + 1); //获取文件后缀名
      var renameFile = new File([file.raw], 'logo.' + suffix, { type: file.raw.type });
      if (suffixArray.indexOf(suffix) === -1) {
        
        this.$message({
          message: this.$t('System.CustomizeConfigPage.ErrorFile'),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        this.docData.fileName = file.url;
        const form = new FormData();
        form.append("files", renameFile);
        form.append("type", "protected");
        form.append("fileType", "logo");
        form.append("moduleName", "config");
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
      }
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
    },
    handleSuccess(response, file, fileList) {
      this.$refs.docData.clearFiles();
      this.$notify({
        title: this.$t('System.CustomizeConfigPage.FileUpSuccessfully'),
        type: "success",
        duration: 2500,
      });
    },
    // 监听上传失败
    handleError(e, file, fileList) {
      const msg = JSON.parse(e.message);
      this.$notify({
        title: msg.message,
        type: "error",
        duration: 2500,
      });
    },
    // // 关闭提示框
    handleClose() {
      this.visibaelFlag = false //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false //提示消息  关闭
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-form-item {
  margin-bottom: 17px;
}

.w400 {
  width: 400px;
  margin-right: 26px;
}

::v-deep .el-button.uploadBox {
  margin-top: 28px;
}

.msg_grey {
  margin-left: 8px;
  height: 24px;
  line-height: 24px;
  color: #807e7e;
  font-size: 12px;
}
</style>