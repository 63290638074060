<template>
    <div>
        <!-- 定制化配置 -->
        <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
            <el-tab-pane :label="$t('System.CustomizeConfigPage.PageCustomization')" name="1">
                <Custom :key="timer1"></Custom>
            </el-tab-pane>
            <el-tab-pane :label="$t('System.CustomizeConfigPage.UserAgreementConfiguration')" name="2">
                <Protocol :key="timer2"></Protocol>
            </el-tab-pane>
            <el-tab-pane :label="$t('System.CustomizeConfigPage.EmailTemplateManagement')" name="3">
                <EmailTemplate :key="timer3"></EmailTemplate>
            </el-tab-pane>
            <el-tab-pane :label="$t('System.CustomizeConfigPage.SMSTemplateManagement')" name="4">
                <NoteTemplate :key="timer4"></NoteTemplate>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import Nav from "@/components/hearder";
import Custom from "./components/CustomizedConfig/custom.vue";
import Protocol from "./components/CustomizedConfig/Protocol.vue";
import EmailTemplate from "./components/CustomizedConfig/emailTemplate.vue";
import NoteTemplate from "./components/CustomizedConfig/noteTemplate.vue";
export default {
    components: { Nav, Custom, Protocol, EmailTemplate, NoteTemplate, },
    data() {
        return {
            activeName: "1",
            timer1: "",
            timer2: "",
            timer3: "",
            timer4: "",
        };
    },
    created() { },
    mounted() { },
    methods: {
        handleLoad(data) {
            let name = data.name;
            if (name == 1) {
                this.timer1 = new Date().getTime();
            } else if (name == 2) {
                this.timer2 = new Date().getTime();
            } else if (name == 3) {
                this.timer3 = new Date().getTime();
            } else if (name == 4) {
                this.timer4 = new Date().getTime();
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.tabs {
    border: 1px solid #dddd;
    border-radius: 5px;
    margin: 10px;
    margin-bottom: 0;
    clear: both;
    padding-bottom: 25px;
    
}

::v-deep .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 10px;
}

::v-deep .el-tabs__item {
    padding: 0 12px;
    height: 36px;
    box-sizing: border-box;
    line-height: 36px;
    display: inline-block;
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    position: relative;
}

::v-deep .el-tabs__item.is-active {
    color: #000;
}

::v-deep .el-tabs__item:hover {
    color: #000;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: #fff;
    border-top: 2px solid #1d89e3;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 1px solid #e4e7ed;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
    border-top: none;
}
</style>